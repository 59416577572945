<template>
  <v-row class="pa-0 ma-0 white">
    <v-col 
      cols="12" 
      class="pt-0 d-flex"
      :class="{'flex-row justify-end': $vuetify.breakpoint.smAndUp, 'flex-column': $vuetify.breakpoint.xsOnly}"
    >
      <span class="blue-grey--text">
        <strong class="font-weight-bold">C.V.:</strong>
        Clientes Visitados
      </span>
      <span 
        class="blue-grey--text"
        :class="{'mx-3':$vuetify.breakpoint.smAndUp}"
      >
        <strong class="font-weight-bold">C.P.:</strong>
        Clientes Planificados
      </span>
      <span class="blue-grey--text">
        <strong class="font-weight-bold">C.F.P.:</strong>
        Clientes Fuera de Planificación
      </span>
    </v-col>
    <v-col cols="12" class="pa-0">
      <v-data-table
        :headers="header_resumen"
        :items="datos"
        :loading="loading"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        disable-sort
        dense
        no-data-text="No hay registros disponibles dentro de la Fechas seleccionadas."
        mobile-breakpoint="50"
        class="data-report"
      >
        <template v-slot:item.ven_des="{item}">
          <v-list-item class="px-0">
            <v-list-item-icon class="ml-1 mr-3">
              <v-avatar
                color="blue lighten-5"
                rounded
                class="rounded-lg"
                size="32"
              >
                <v-icon
                  color="blue-grey lighten-2"
                  size="19"
                >
                  mdi-account
                </v-icon>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span class="blue-grey--text font-weight-medium"><strong class="primary--text">{{item.co_ven}}</strong> - {{item.ven_des}} </span>
              </v-list-item-title>             
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.hora_inicio="{item}">
          <div class="d-flex justify-center">
            <span class="blue-grey--text" v-if="item.hora_inicio">
              <v-icon 
                color="icono"
                size="16" 
                class="mt-n1"
              >mdi-clock-outline</v-icon> 
              {{item.hora_inicio | time }}
            </span>
          </div>
        </template>
        <template v-slot:item.hora_fin="{item}">
          <div class="d-flex justify-center">
            <span class="blue-grey--text" v-if="item.hora_fin">
              <v-icon 
                color="icono"
                size="16" 
                class="mt-n1"
              >mdi-alarm-check</v-icon> 
              {{item.hora_fin | time}}
            </span>
          </div>
        </template>
        <template v-slot:item.evi="{item}">
          <div class="d-flex flex-column justify-end">            
            <span 
              class="primary--text text-h6 font-weight-bold mr-2 text-right"
            >{{item.evi}}%</span>
            <v-progress-linear
              :value="item.evi"
              height="10"
              color="info"
            />
          </div>
        </template>
        <template v-slot:item.clientes_planificados="{item}">
          <div class="d-flex flex-row align-center justify-end">
            <div class="d-flex justify-center align-center">
              <span class="blue-grey--text text-caption text--lighten-3 font-weight-medium mx-1">C.V.</span>
              <span class="primary--text font-weight-bold text-h3" v-text="item.cv" />
            </div>
            <span class="primary--text font-weight-bold text-h4 mx-1">/</span>
            <div class="d-flex justify-center align-center">
              <span class="primary--text font-weight-bold text-h3" v-text="item.cp" />
              <span class="blue-grey--text text--lighten-3 caption font-weight-medium mx-1">C.P.</span>
            </div>
          </div>
        </template>
        <template v-slot:item.cfp="{item}">
          <span class="font-weight-bold" v-text="item.cfp" />
        </template>
        <template #loading>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="table-tbody"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DataResumen',
  props:{
    datos: {
      type: Array,
      default: () => ([]),
    },    
    loading:{
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    header_resumen:[
      { text: 'Vendedor', value: 'ven_des', class: 'header-data' },
      { text: 'Inicio', value: 'hora_inicio', class: 'header-data', align: 'center' },
      { text: 'Fin', value: 'hora_fin', class: 'header-data', align: 'center' },
      { text: 'CV/CP', value: 'clientes_planificados', class: 'header-data', align: 'center'},
      { text: 'Efectividad', value: 'evi', class: 'header-data', width: 200 },
      // { text: 'C.V', value: 'clientes_visitados', class: 'header-data', align: 'center'},
      { text: 'C.F.P', value: 'cfp', class: 'header-data', align: 'center'},      
    ]
  }),
  filters: {
    time(val) {
      return moment(val).format('hh:mm A')
    }
  }
}
</script>

<style lang="sass">
.data-report tbody tr:nth-of-type(odd)
  background-color: #FAFAFA
  border-color: #ECEFF1 !important

// .data-report table > tbody > tr > td:nth-child(1)
//   position: sticky !important
//   position: -webkit-sticky !important
//   left: 0
//   z-index: 98


// .data-report table > thead > tr > th:nth-child(1)
//   z-index: 99


</style>
